<template>
  <v-container v-if="!id">
    <v-row>
      <v-col class="subtitle-2 ems-blue-grey--text text-center">
        無電表資料
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid class="overView-wrap" v-else>
    <v-row class="px-3">
      <v-col cols="12">
        <span class="font-weight-bold accent--text title">電驛狀態</span>
        <span class="ml-2 ems-blue-grey--text">
          {{
            meterRelayData && meterRelayData.name
              ? `已選擇「${meterRelayData.name}」，${meterRelayStatus.name}。`
              : '--'
          }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card
          class="mx-auto ems-opacity-50-bg frosted-glass"
          elevation="0"
          height="100%"
          min-height="80px"
        >
          <v-card-subtitle class="pb-1 ems-blue-grey--text">
            與去年同個月相比
          </v-card-subtitle>
          <v-list-item>
            <v-list-item-content v-if="energy.loading">
              <v-skeleton-loader
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
            </v-list-item-content>
            <v-list-item-content class="pa-0" v-else>
              <div class="d-flex justify-space-between align-center">
                <span
                  v-if="!typeOfEnergyYoyIsNum"
                  class="headline font-weight-bold ems-accent-secondary--text"
                >
                  --
                </span>
                <span
                  v-else-if="energy.data.yoy >= 0"
                  class="headline font-weight-bold ems-error--text"
                >
                  + {{ numeral(energy.data.yoy).format('0.0%') }}
                </span>
                <span
                  v-else-if="energy.data.yoy < 0"
                  class="headline font-weight-bold secondary--text"
                >
                  {{ numeral(energy.data.yoy).format('0.0%') }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-card-subtitle class="pb-1 ems-blue-grey--text">
            當月累積電度</v-card-subtitle
          >
          <v-list-item>
            <v-list-item-content v-if="energy.loading">
              <v-skeleton-loader
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
            </v-list-item-content>
            <v-list-item-content class="pa-0" v-else>
              <div class="d-flex justify-space-between align-center">
                <span class="headline font-weight-bold ems-accent--text">{{
                  energy.data.energy || '--'
                }}</span>
                <span class="ems-blue-grey--text"> kWh</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <template v-if="ac.data">
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(item, key) in ac.data"
          :key="key"
        >
          <v-card
            class="mx-auto ems-opacity-50-bg frosted-glass"
            elevation="0"
            height="100%"
            min-height="180px"
          >
            <v-card-title
              class="pb-1 primary--text subtitle-1 font-weight-bold"
            >
              <v-row>
                <v-col cols="auto">
                  <v-img
                    contain
                    max-height="48"
                    max-width="110"
                    src="@/assets/image/Group 124@3x.png"
                  ></v-img>
                </v-col>
                <v-col class="pl-0">
                  <div class="ml-2">
                    {{ item.name }} <br /><span class="text-caption"
                      >{{ item.serialNumber || 'null' }} |
                      {{ item.modbusId || 'null' }}</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <template>
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-row class="my-0">
                    <v-col cols="6">
                      <div class="d-flex align-center">
                        <v-img
                          v-if="isIRAc(item)"
                          contain
                          max-height="18"
                          max-width="18"
                          :src="require('@/assets/image/acControl/ir.png')"
                        ></v-img>
                        <v-icon
                          v-else-if="
                            ['POWER_ON', 'POWER_OFF'].includes(item.status)
                          "
                          class="mr-2"
                          x-small
                          :color="
                            item.status === 'POWER_ON' ? 'success' : 'error'
                          "
                        >
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <span class="font-weight-bold accent--text">
                          {{ acStatusText(item) }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6" v-if="!isIRAc(item)">
                      <div class="mb-2 ems-blue-grey--text">室內溫度</div>
                      <div>
                        <span class="accent--text font-weight-bold text-h4">{{
                          item.status !== 'POWER_ON'
                            ? '--'
                            : getTemperature(item.temperature).text
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <div v-if="isIRAc(item)" class="px-4 pb-2 ems-blue-grey--text">
                由IR下控的的冷氣，顯示數值不代表實際運轉狀況
              </div>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-row class="my-0 text-center">
                    <template v-if="isIRAc(item)">
                      <v-col cols="12">
                        <div class="mb-2 ems-blue-grey--text">IR設定溫度</div>
                        <div>
                          <span class="accent--text font-weight-bold title">{{
                            item.status !== 'POWER_ON'
                              ? '--'
                              : getTemperature(item.settingTemperature).text
                          }}</span>
                        </div>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="6">
                        <div class="mb-2 ems-blue-grey--text">模式</div>
                        <div class="d-flex justify-center">
                          <v-icon
                            size="sm"
                            right
                            color="accent"
                            v-if="item.status === 'POWER_ON'"
                            >{{ getMode(item.mode).icon }}</v-icon
                          >
                          <span class="accent--text font-weight-bold title">{{
                            item.status !== 'POWER_ON'
                              ? '--'
                              : getMode(item.mode).text
                          }}</span>
                        </div>
                      </v-col>
                      <v-col cols="6" style="border-left: 1px solid #dddddd">
                        <div class="mb-2 ems-blue-grey--text">設定溫度</div>
                        <div>
                          <span class="accent--text font-weight-bold title">{{
                            item.status !== 'POWER_ON'
                              ? '--'
                              : getTemperature(item.settingTemperature).text
                          }}</span>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row class="px-3">
      <v-col cols="12" sm="4">
        <v-card
          class="mx-auto frosted-glass ClassMeter"
          elevation="0"
          height="100%"
          max-height="240px"
          min-height="150px"
        >
          <v-card-title
            class="subtitle-1 font-weight-bold ems-accent-secondary--text py-2"
            >即時功率</v-card-title
          >
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
              v-if="realtimeInfo.loading && !realtimeInfo.data"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
            </v-list-item-content>
            <v-list-item-content class="pa-0" v-else>
              <div class="d-flex justify-space-between align-center">
                <span
                  class="headline font-weight-bold ems-accent--text"
                  v-if="realtimeInfo.data && realtimeInfo.data.power"
                  >{{ realtimeInfo.data.power }}</span
                >
                <span class="ems-blue-grey--text"> kW</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card
          class="mx-auto frosted-glass ClassMeter"
          elevation="0"
          height="100%"
          max-height="240px"
          min-height="80px"
        >
          <v-card-title
            class="subtitle-1 font-weight-bold ems-accent-secondary--text py-2"
            >電壓</v-card-title
          >
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
              v-if="realtimeInfo.loading && !realtimeInfo.data"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
            </v-list-item-content>
            <v-list-item-content class="pa-0" v-else>
              <div class="d-flex justify-space-between align-center">
                <span
                  class="headline font-weight-bold ems-accent--text"
                  v-if="realtimeInfo.data && realtimeInfo.data.voltage"
                  >{{ realtimeInfo.data.voltage }}</span
                >
                <span class="ems-blue-grey--text"> V </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card
          class="mx-auto frosted-glass ClassMeter"
          elevation="0"
          height="100%"
          max-height="240px"
          min-height="80px"
        >
          <v-card-title
            class="subtitle-1 font-weight-bold ems-accent-secondary--text py-2"
            >電流</v-card-title
          >
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
              v-if="realtimeInfo.loading && !realtimeInfo.data"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
            </v-list-item-content>
            <v-list-item-content class="pa-0" v-else>
              <div class="d-flex justify-space-between align-center">
                <span
                  class="headline font-weight-bold ems-accent--text"
                  v-if="realtimeInfo.data && realtimeInfo.data.voltage"
                  >{{ realtimeInfo.data.current }}</span
                >
                <span class="ems-blue-grey--text"> A </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Meter from '@/api/ems/Meter'
import { countdownReload } from '@/shared/utils'

export default {
  name: 'ClassMeter',
  props: {
    id: {
      type: String
    }
  },
  data: () => ({
    timer: null,
    realtimeInfo: {
      loading: true,
      data: null
    },
    relay: {
      loading: true,
      data: null
    },
    energy: {
      loading: true,
      data: {}
    },
    demand: {
      loading: true,
      data: null
    },
    ac: {
      loading: true,
      data: []
    }
  }),
  watch: {
    id() {
      this.init()
      clearInterval(this.timer)
      this.timer = countdownReload(this.init, 15)
    }
  },
  computed: {
    ...mapGetters({
      statusOption: 'ac/statusOption',
      modeOption: 'ac/modeOption',
      temperatureOption: 'ac/temperatureOption',
      relayActionOption: 'ac/relayActionOption',
      relayStatusOption: 'ac/relayStatusOption'
    }),
    meterRelayData() {
      if (!this.relay.data) {
        return null
      }
      return this.relayActionOption.find((relay) => {
        return this.relay.data.emsclassrelaymode === relay.value
      })
    },
    meterRelayStatus() {
      if (!this.relay.data) {
        return null
      }
      return this.relayStatusOption.find((relay) => {
        return this.relay.data.emsclassrelaystatus === relay.value
      })
    },
    typeOfEnergyYoyIsNum() {
      return this.energy.data && typeof this.energy.data.yoy === 'number'
    }
  },
  methods: {
    init() {
      if (!this.id) return
      this.getMeterRealtime()
      this.getMeterRelay()
      this.getMeterEnergy()
      this.getMeterAC()
    },
    async getMeterRealtime() {
      this.realtimeInfo.loading = true
      try {
        const { data } = await Meter.getMeterRealtime(this.id)
        this.realtimeInfo.data = data
      } catch (error) {
        console.error(error)
      } finally {
        this.realtimeInfo.loading = false
      }
    },
    async getMeterRelay() {
      this.relay.loading = true
      try {
        const { data } = await Meter.getMeterRelay(this.id)
        this.relay.data = data
      } catch (error) {
        console.error(error)
      } finally {
        this.relay.loading = false
      }
    },
    async getMeterEnergy() {
      this.energy.loading = true
      try {
        const { data } = await Meter.getMeterDetail(this.id)
        this.energy.data = data
      } catch (error) {
        console.error(error)
      } finally {
        this.energy.loading = false
      }
    },
    async getMeterAC() {
      this.ac.loading = true
      try {
        const { data } = await Meter.getMeterAC(this.id)
        this.ac.data = data
      } catch (error) {
        console.error(error)
      } finally {
        this.ac.loading = false
      }
    },
    getMode(item) {
      return (
        this.modeOption.find((d) => d.value === item) || {
          icon: '--',
          text: '--'
        }
      )
    },
    getTemperature(item) {
      return (
        this.temperatureOption.find(
          (d) => d.acResponseValue === String(item)
        ) || {
          text: '--'
        }
      )
    },
    isIRAc(ac) {
      return ac.type === 'IR_AIRCONDITIONER'
    },
    acStatusText(ac) {
      if (this.isIRAc(ac)) return 'IR控制'
      if (ac.status === 'POWER_ON') return '運轉中'
      if (ac.status === 'POWER_OFF') return '關閉'
      return ''
    }
  },
  mounted() {
    this.init()
    this.timer = countdownReload(this.init, 15)
  },
  destroyed() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
.ClassMeter {
  background: rgba(#0072f7, 0.15);
}

.btn-Repair {
  background-image: linear-gradient(to bottom, #00ddcb, #0092fd);
  width: 120px;
  height: 120px;
  // min-width: 100px;
  // min-height: 100px;
  // max-width: 180px;
  // max-height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
}
</style>
