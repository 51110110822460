<template>
  <v-container fluid class="fill-height">
    <div class="ammeters-wrap" v-if="ammeters.length > 0">
      <vue-scroll>
        <v-row dense class="ammeters-wrap-row pa-1">
          <v-col
            v-for="item in ammeters"
            :key="item.id"
            :class="['ammeter', { closed: expand.indexOf(item.id) < 0 }]"
          >
            <v-card
              elevation="0"
              height="100%"
              color="rgba(255, 255, 255, 0.25)"
              class="frosted-glass"
            >
              <v-card-title
                class="subtitle-1 font-weight-bold primary--text pb-1"
              >
                <div
                  :class="[
                    'ammeter-title-wrap d-flex align-center',
                    { expanded: expand.indexOf(item.id) > -1 }
                  ]"
                >
                  <div class="ammeter-title">
                    {{ item.name }}
                  </div>
                  <div class="ammeter-title" @click="expandItem(item)">
                    <v-btn
                      icon
                      color="primary"
                      v-if="expand.indexOf(item.id) > -1"
                    >
                      <v-icon> mdi-chevron-left</v-icon></v-btn
                    >
                    <v-btn icon color="primary" v-else>
                      <v-icon> mdi-chevron-down</v-icon></v-btn
                    >
                  </div>
                </div>
              </v-card-title>
              <template v-if="expand.indexOf(item.id) > -1">
                <ClassMeter :id="item.id" type="class" />
              </template>
            </v-card>
          </v-col>
          <!-- <v-col class="ammeter ammeter-add">
            <v-card hover v-ripple="{ class: 'ems-blue-grey--text' }" elevation="0" outlined height="100%">
              <div class="d-flex">
                 <v-icon color="ems-blue-grey"> mdi-plus-circle</v-icon>
              </div>
            </v-card>
          </v-col> -->
        </v-row>
      </vue-scroll>
    </div>
    <v-row v-else>
      <v-col class="ems-blue-grey--text"> 無電表資料 </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Meter from '@/api/ems/Meter'
import ClassMeter from '@/components/ClassMeter'
// import GoogleMap from '@/components/gMap'
// import MeterSchool from '@/api/ems/MeterSchool'
// import { countdownReload } from '@/shared/utils'
import { mapActions } from 'vuex'

export default {
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  components: {
    ClassMeter
    // Echart
    // GoogleMap
  },
  data() {
    return {
      expand: [],
      timer: null,
      menu: false,
      ammeters: []
    }
  },
  watch: {
    id() {
      this.init()
    }
  },
  computed: {
    id() {
      return this.tagId
    }
  },
  methods: {
    ...mapActions({
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    init() {
      this.getClassMeters()
    },
    async getClassMeters() {
      this.setOverlayLoading(true)
      this.ammeters = []
      try {
        const { data } = await Meter.getClassMeters(this.tagId)
        this.ammeters = data
        this.expand = [data[0].id]
      } catch (error) {
        console.error(error)
      } finally {
        this.setOverlayLoading(false)
      }
    },
    expandItem(item) {
      if (this.expand.indexOf(item.id) > -1) {
        this.expand = this.expand.filter((d) => d !== item.id)
      } else {
        this.expand = [item.id]
      }
      // return this.expand.indexOf(item.id) < 0 ? 'auto' : ''
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {}
}
</script>

<style lang="scss" scoped>
.ammeters-wrap {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 64px - 48px - 50px);
  .ammeters-wrap-row {
    width: 99%;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.ammeter {
  min-width: 300px;
  min-height: 360px;
  .ammeter-title-wrap {
    justify-content: space-between;
    width: 100%;
    transform-origin: center left;
    transition: transform 0.4s;
  }
  .v-card {
    height: 100%;
  }
  transition: 0.5s;
  &.ammeter-add {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    min-width: auto;
    .v-card {
      width: 70px;
      background: rgba(255, 255, 255, 0);
      border: 2px dashed var(--v-ems-blue-grey-base);
      div {
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.closed {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    min-width: auto;
    .v-card {
      width: 70px;
    }
    .ammeter-title-wrap {
      transition: transform 0.3s;
      transform: translate(18px, 0px) rotateZ(90deg);
      white-space: nowrap;
      height: 100%;
      display: inline-block;
      margin-right: 15px;
      button {
        margin-left: 15px;
      }
    }
  }
}
</style>
